import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './IntelligentSystem.module.scss'
import { IntelligentSystemProps } from './types'
import * as THREE from 'three'
import {
	Box3,
	Color,
	DoubleSide,
	Float32BufferAttribute,
	InstancedBufferAttribute,
	InstancedMesh,
	Object3D,
	PerspectiveCamera,
	PlaneGeometry,
	Scene,
	ShaderMaterial,
	Vector3,
	WebGLRenderer,
} from 'three'
import { clamp, lerp, map } from 'components/_utils/mathUtils'
import { VideoGif } from 'components/basic/VideoGif'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import easingFunctions from 'utils/easingFunctions'
import { Button } from 'components/Phantom/Button'
import { TakeACloserLookConnected } from 'components/Pod4TakeACloserLook'
import { FullScreenModal } from '../ThisIsPod4Ultra'
import Stats from 'three/examples/jsm/libs/stats.module'
import { isWebGLAvailable } from 'components/_utils/threeUtils'

export const IntelligentSystem: FC<IntelligentSystemProps> = (props) => {
	const { id } = props
	const [closerLookVisible, setCloserLookVisible] = useState(false)
	const [containerVisible, setContainerVisible] = useState(false)
	const [ctaVisible, setCtaVisible] = useState(false)

	const containerRef = useRef<HTMLDivElement>(null)

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger)

		const videoTriggers = ['#cover-falling-video', '#heatingcooling-video', '#elevation-animation']

		videoTriggers.forEach((trigger) => {
			gsap.fromTo(
				trigger,
				{
					y: 20,
					opacity: 0,
				},
				{
					scrollTrigger: {
						trigger: trigger,
						start: 'top 70%',
						end: '+=20',
						// markers: true,
						onEnter: () => {
							const video = document.getElementById(trigger.replace('#', '')) as HTMLVideoElement
							try {
								video?.play()
							} catch (e) {
								console.error(e)
							}
						},

						invalidateOnRefresh: true,
						toggleActions: 'restart none none reverse',
					},
					ease: 'power3.inOut',
					duration: 1,

					y: 0,
					opacity: 1,
				}
			)
		})

		const textTriggers = ['#header-title', '#add-it-to-any-bed', '#and-now-it-elevates', '#it-cools']

		gsap.fromTo(
			'#header-introducing',
			{
				y: 100,
				opacity: 0,
			},
			{
				y: 0,
				opacity: 0.25,

				scrollTrigger: {
					trigger: '#header-introducing',
					start: 'top bottom',
					end: '+=20',
					// markers: true,

					toggleActions: 'restart none none reverse',
					invalidateOnRefresh: true,
				},
				ease: 'power3.inOut',

				duration: 1,
			}
		)

		textTriggers.forEach((trigger) => {
			gsap.fromTo(
				trigger,
				{
					y: 100,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,

					scrollTrigger: {
						trigger: trigger,
						start: 'top bottom',
						end: '+=20',
						// markers: true,

						toggleActions: 'restart none none reverse',
						invalidateOnRefresh: true,
					},
					ease: 'power3.inOut',

					duration: 1,
				}
			)
		})

		gsap.fromTo(
			'#it-heats',
			{
				y: 100,
				opacity: 0,
			},
			{
				y: 0,
				opacity: 1,

				scrollTrigger: {
					trigger: '#it-heats',
					start: 'top 90%',
					end: '+=20',
					// markers: true,

					toggleActions: 'restart none none reverse',
					invalidateOnRefresh: true,
				},

				ease: 'power3.inOut',

				duration: 1,
			}
		)
	})

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				setContainerVisible(entry.isIntersecting)
			})
		})

		const ctaObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				setCtaVisible(entry.isIntersecting)
			})
		})

		if (containerRef.current) {
			observer.observe(containerRef.current)
		}

		const ctaElement = document.getElementById('intelligent-system-cta')
		if (ctaElement) {
			ctaObserver.observe(ctaElement)
		}

		return () => {
			if (containerRef.current) {
				observer.unobserve(containerRef.current)
			}

			if (ctaElement) {
				ctaObserver.unobserve(ctaElement)
			}
		}
	}, [])

	return (
		<>
			<FullScreenModal
				closeAction={() => {
					setCloserLookVisible(false)
				}}
				visible={closerLookVisible}
			>
				{ctaVisible && <TakeACloserLookConnected />}
			</FullScreenModal>

			<section
				className={styles.container}
				ref={containerRef}
				id={id}
			>
				<header id={'intelligent-header'}>
					<p id={'header-introducing'}>Introducing Pod 4 Ultra</p>
					<h2 id={'header-title'}>The intelligent system that gives you up to one more hour of sleep every night</h2>
				</header>

				<div className={styles.base_section}>
					<p className={styles.basic_big_text}>
						<span id={'add-it-to-any-bed'}>Add it to any bed</span>
					</p>
					<div className={styles.media_container}>
						<VideoGif
							src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/intelligent_system_cover_fall_fast.mp4'}
							autoPlay={false}
							loop={false}
							id={'cover-falling-video'}
						/>
					</div>
				</div>

				<div className={styles.cools_section}>
					<p className={styles.basic_big_text}>
						<span id={'it-cools'}>It cools,</span>
						<span id={'it-heats'}> it heats</span>
					</p>
					<div className={styles.media_container}>
						<VideoGif
							src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/heatscoolsreversereverseeverybodyclapyourhands.mp4'}
							autoPlay={false}
							loop={true}
							id={'heatingcooling-video'}
						/>
					</div>
				</div>

				<div className={styles.elevates_section}>
					<p className={styles.basic_big_text}>
						<span id={'and-now-it-elevates'}> And now, it elevates</span>
					</p>
					<div className={styles.media_container}>
						<VideoGif
							src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elevate_hyperspeed.mp4'}
							id={'elevation-animation'}
							autoPlay={false}
							loop={false}
						/>
					</div>
				</div>
				<div
					id={'automatically_spacer'}
					className={styles.automatically_spacer}
				></div>

				<div className={styles.automatically_section}>
					<div className={styles.automatically_cart}>
						<AutomaticallyParticles />
						<div className={styles.automatically_static_content}>
							<p
								className={styles.automatically_text}
								id={'automatically_real_text'}
							>
								Automatically
							</p>

							<Button.Plus
								id={'intelligent-system-cta'}
								className={styles.automatically_cta}
								onClick={() => {
									setCloserLookVisible(true)
								}}
							>
								Take a closer look
							</Button.Plus>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

const SCATTER_DISTANCE = 4000
const PARTICLE_ADD_CHANCE = 1

const AutomaticallyParticles: FC = () => {
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const scatterFactor = useRef(1)

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger)

		const tl = gsap.timeline()
		tl.to(
			`#automatically-particles`,
			{
				ease: 'none',
				onUpdate: function () {
					const progress = this.progress()
					scatterFactor.current = clamp(easingFunctions.easeInQuad(1 - progress), 0, 1)
				},
				duration: 0.97,
			},
			0
		)

		const initialDuration = 0.95
		const nudge = 0.01

		tl.fromTo(
			`#automatically-particles`,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: initialDuration,
			},
			0
		)

		tl.to(
			`#automatically-particles`,
			{
				opacity: 0,
				duration: 1 - initialDuration + nudge,
			},
			initialDuration + nudge
		)

		tl.to(
			`#automatically_real_text`,
			{
				opacity: 1,
				duration: 1 - initialDuration,
			},
			initialDuration
		)

		tl.from(
			'#automatically-particles',
			{
				filter: 'blur(20px)',
				duration: 0.5,
			},
			0
		)

		tl.fromTo(
			`#intelligent-system-cta`,
			{
				opacity: 0,
				y: 100,
				pointerEvents: 'none',
			},
			{
				opacity: 1,
				y: 0,
				pointerEvents: 'auto',
				duration: 1 - initialDuration,
				ease: 'power3.inOut',
			},
			initialDuration - 0.05
		)

		ScrollTrigger.create({
			trigger: '#automatically_spacer',
			start: 'top bottom',
			end: '+=90%',
			scrub: 2,
			animation: tl,
			invalidateOnRefresh: true,
			// markers: true,
		})
	})

	useEffect(() => {
		if (!canvasRef.current) return () => {}
		if (!isWebGLAvailable()) return () => {}
		const scene = new Scene()
		const canvas = canvasRef.current as HTMLCanvasElement

		const parentWidth = canvas.parentElement?.clientWidth || window.innerWidth
		const parentHeight = canvas.parentElement?.clientHeight || window.innerHeight
		const camera = new PerspectiveCamera(75, parentWidth / parentHeight, 0.1, 10000)

		const renderer = new WebGLRenderer({ canvas: canvasRef.current as HTMLCanvasElement, alpha: true })
		const pixelRatio = Math.max(2, window.devicePixelRatio)
		renderer.setPixelRatio(pixelRatio)
		renderer.setSize(parentWidth, parentHeight)
		renderer.setClearColor(0x000000, 0)
		renderer.toneMapping = THREE.ReinhardToneMapping

		const resizeCanvas = () => {
			const parentWidth = canvas.parentElement?.clientWidth || window.innerWidth
			const parentHeight = canvas.parentElement?.clientHeight || window.innerHeight
			camera.aspect = parentWidth / parentHeight
			camera.updateProjectionMatrix()
			renderer.setSize(parentWidth, parentHeight)

			const cameraZoomT = map(window.innerWidth, 320, 1942, 0, 1)
			camera.position.z = lerp(2000, 600, cameraZoomT)

			material.uniforms.circleRadius.value = lerp(4, 2, cameraZoomT)
		}

		window.addEventListener('resize', resizeCanvas)

		// Create a fake 2D canvas to draw text
		const textCanvas = document.createElement('canvas')
		textCanvas.width = 2048
		textCanvas.height = 512
		const ctx = textCanvas.getContext('2d')

		// Draw text on the canvas
		ctx.font = `200px ${getComputedStyle(canvasRef.current).fontFamily}`
		ctx.fillText('Automatically', 50, 400)

		// Read pixel data from the canvas
		const imageData = ctx.getImageData(0, 0, textCanvas.width, textCanvas.height)
		const data = imageData.data

		// Destroy the canvas
		textCanvas.remove()

		// Create particle geometry using BufferGeometry
		const positions: number[] = []

		for (let y = 0; y < textCanvas.height; y++) {
			for (let x = 0; x < textCanvas.width; x++) {
				const alpha = data[(y * textCanvas.width + x) * 4 + 3]
				const roll = Math.random()
				if (alpha > 128 && roll < PARTICLE_ADD_CHANCE) {
					// Check if pixel is mostly opaque
					positions.push(
						x - textCanvas.width / 2,
						-y + textCanvas.height / 2, // flip y-axis to match Three.js
						0
					)
				}
			}
		}

		const instancedMesh = createParticleSpheres(positions)
		scene.add(instancedMesh)

		// center instanced mesh based on bounding box
		const box = new Box3().setFromObject(instancedMesh)
		const center = new Vector3()
		box.getCenter(center)
		instancedMesh.position.sub(center)

		camera.position.z = 600

		const numInstances = positions.length / 3
		const directions = new Array(numInstances).fill(null).map(() => new Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize())
		const initialPositions = positions.slice()

		let animationId: number

		const getScreenPosition = (box: Box3, camera: PerspectiveCamera, renderer: WebGLRenderer) => {
			// Define the 4 front-facing corners of the box
			const corners = [
				new Vector3(box.min.x, box.min.y, box.max.z),
				new Vector3(box.min.x, box.max.y, box.max.z),
				new Vector3(box.max.x, box.min.y, box.max.z),
				new Vector3(box.max.x, box.max.y, box.max.z),
			]

			// Project each corner to screen space
			const screenPositions = corners.map((corner) => {
				corner.project(camera)

				// Convert the normalized position (-1, 1) to screen coordinates, accounting for pixel ratio
				const widthHalf = renderer.domElement.width / 2 / pixelRatio
				const heightHalf = renderer.domElement.height / 2 / pixelRatio

				return {
					x: corner.x * widthHalf + widthHalf,
					y: -(corner.y * heightHalf) + heightHalf,
				}
			})

			return {
				bottomLeft: screenPositions[0],
				topLeft: screenPositions[1],
				bottomRight: screenPositions[2],
				topRight: screenPositions[3],
			}
		}

		const automaticRealText = document.getElementById('automatically_real_text') as HTMLParagraphElement
		const intelligentSystemCTA = document.getElementById('intelligent-system-cta') as HTMLButtonElement

		// const boxHelper = new THREE.Box3Helper(box, 0xff0f00)
		// scene.add(boxHelper)

		const fullStats = new Stats()

		//document.body.appendChild(fullStats.dom)

		const scatterInitialPos = new Vector3()
		const temp = new Vector3()
		const euler = new THREE.Euler()

		function animate() {
			animationId = requestAnimationFrame(animate)
			if (scatterFactor.current == 0 || scatterFactor.current == 1) {
				// Don't waste resources if scatter factor is at 0 or 1, as particles are not moving
				return
			}
			fullStats.update()

			euler.set((scatterFactor.current * Math.PI) / 3, scatterFactor.current * Math.PI, scatterFactor.current * Math.PI * 1.4)

			material.uniforms.scatterFactor.value = scatterFactor.current
			// Update positions based on scatter factor
			for (let i = 0, j = 0; i < numInstances; i++, j += 3) {
				const direction = directions[i]
				scatterInitialPos.set(initialPositions[j], initialPositions[j + 1], initialPositions[j + 2])
				const newPosition = scatterInitialPos.add(direction.clone().multiplyScalar(scatterFactor.current * SCATTER_DISTANCE))

				temp.set(newPosition.x, newPosition.y, newPosition.z)
				temp.applyEuler(euler)

				instancedMesh.geometry.attributes.instanceOffset.setXYZ(i, temp.x, temp.y, temp.z)
			}
			instancedMesh.instanceMatrix.needsUpdate = true
			instancedMesh.geometry.attributes.instanceOffset.needsUpdate = true

			box.setFromObject(instancedMesh) // Update bounding box

			const boxScreenPosition = getScreenPosition(box, camera, renderer)
			const textScreenWidth = boxScreenPosition.topRight.x - boxScreenPosition.topLeft.x
			const textScreenHeight = boxScreenPosition.bottomLeft.y - boxScreenPosition.topLeft.y
			automaticRealText.style.fontSize = `${textScreenWidth * 0.177}px`
			intelligentSystemCTA.style.marginTop = `calc(4rem + ${Math.max(0, textScreenHeight * 2)}px)`

			renderer.render(scene, camera)
		}

		resizeCanvas()
		animationId = requestAnimationFrame(animate)

		return () => {
			cancelAnimationFrame(animationId)
			renderer.dispose()

			window.removeEventListener('resize', resizeCanvas)

			scene.remove(instancedMesh)
			instancedMesh.geometry.dispose()
			instancedMesh.material.dispose()

			try {
				document.body.removeChild(fullStats.dom)
			} catch (e) {
				console.error(e)
			}

			renderer.dispose()
		}
	}, [])

	return (
		<canvas
			id={'automatically-particles'}
			ref={canvasRef}
		/>
	)
}

export const material = new ShaderMaterial({
	vertexShader: `
    // Vertex attributes
    attribute vec3 instanceColor;      // Color for each instance
    attribute vec3 instanceOffset;     // Position offset for each instance

    // Varying variables to pass data to the fragment shader
    varying vec3 vColor;
    varying vec2 vUv;
    
    uniform float circleRadius;

    void main() {
      // Set the color for the fragment shader
      vColor = instanceColor;
      float radius = circleRadius;

      vec4 worldPosition = modelViewMatrix * (vec4(position * radius, 1.0) + vec4(instanceOffset, 0.0));

      // Calculate the final vertex position in clip space
      gl_Position = projectionMatrix * worldPosition;

      // Calculate the UV coordinates for the fragment shader
      vUv = position.xy * 0.5 + vec2(0.5);
    }
  `,
	fragmentShader: `
    // Varying variables from the vertex shader
    varying vec3 vColor;
    varying vec2 vUv;
    uniform float scatterFactor;

    void main() {
		// Calculate the distance from the fragment position to the center of the quad
		float distanceFromCenter = length(vUv - vec2(0.5));
		
		float radius = 0.25;
		  
		if (distanceFromCenter > radius) {
			discard;
		}
		
		float attenuationFactor = 10.0;
		float rT = pow(1.0 - scatterFactor, attenuationFactor);
		float minR = 0.3 + rT * 0.7;

		// Define the range over which alpha decreases
		float minDistance = minR * radius;
		float maxDistance = 1.0 * radius;
	
		// Map the distance to an alpha value between 0 and 1
		float alpha = 1.0 - smoothstep(minDistance, maxDistance, distanceFromCenter);
	
		// Set the final color with alpha blending
		vec4 finalColor = vec4(vColor, alpha);
	
		// Output the final color
		gl_FragColor = finalColor;
	
		// Set the final color for the fragment
		gl_FragColor = finalColor;
    }
  `,
	side: DoubleSide,
	transparent: true,
	uniforms: {
		scatterFactor: { value: 1 },
		circleRadius: { value: 2 },
	},
})

const createParticleSpheres = (positions: number[]) => {
	const numInstances = positions.length / 3
	const colors = new Float32Array(numInstances * 3) // RGB for each instance
	const geometry = new PlaneGeometry(1, 1) // Small sphere geometry
	const instancedMesh = new InstancedMesh(geometry, material, numInstances)

	const temp = new Object3D()

	const box = new Box3().setFromBufferAttribute(new Float32BufferAttribute(positions, 3))
	const minX = box.min.x
	const maxX = box.max.x

	const color = new Color()
	const startColor = new Color('#918eff').setHSL(241 / 360, 1, 0.7)
	const endColor = new Color('#c360ff').setHSL(277 / 360, 1, 0.7)

	const offsets = new Float32Array(numInstances * 3)

	for (let i = 0; i < numInstances; i++) {
		const j = i * 3
		temp.position.set(positions[j], positions[j + 1], positions[j + 2])
		temp.updateMatrix()
		instancedMesh.setMatrixAt(i, temp.matrix)

		const t = lerp(0, 1, (positions[j] - minX) / (maxX - minX))

		color.lerpColors(startColor, endColor, t)
		colors[i * 3] = color.r
		colors[i * 3 + 1] = color.g
		colors[i * 3 + 2] = color.b

		offsets.set([positions[j], positions[j + 1], positions[j + 2]], i * 3)
	}

	instancedMesh.geometry.setAttribute('instanceOffset', new InstancedBufferAttribute(offsets, 3))
	instancedMesh.geometry.setAttribute('instanceColor', new InstancedBufferAttribute(colors, 3))
	instancedMesh.instanceMatrix.needsUpdate = true

	return instancedMesh
}
