import { FC } from 'react'
import { WhoWeAreProps } from './types'
import styles from './WhoWeAre.module.scss'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'

const imageSources = [
	{
		mediaQuery: '(max-width: 599px)',
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/8Sleep_13_02_20032024.png',
	},
	{
		mediaQuery: '(min-width: 600px)',
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/8Sleep_13_02_20032024.png',
	},
]

export const WhoWeAre: FC<WhoWeAreProps> = (props) => {
	return (
		<section className={styles.container}>
			<article className={styles.wrapper}>
				<Img
					sources={[
						{
							mediaQuery: '(max-width: 599px)',
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/who-we-are-mobile_crop.jpeg',
							dprHeight: 1600,
						},
						{
							mediaQuery: '(min-width: 600px)',
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/who-we-are-desktop.jpeg',
							dprHeight: 1600,
						},
					]}
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/who-we-are-desktop.jpeg'}
					alt={'Woman lying in bed'}
					dprHeight={1600}
					className={styles.image}
					objectFit={'cover'}
				/>
				<small>Who we are</small>
				<h2>{props.title ?? <>We’ve improved over 72&nbsp;million nights of sleep</>}</h2>
				<Button.Primary
					id={'personal_sleep_cta'}
					className={styles.header_cta}
					href={'/pod-cover'}
				>
					Learn more
				</Button.Primary>
			</article>
		</section>
	)
}
